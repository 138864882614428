import { useState } from "react";

import InputFile from "../components/inputFile";
import View from "../components/view";

import { getWSJSON } from "../helper/xlsx";

import { CancelledData, DataFollowUpViews } from "../types";

export default function Data574() {
	const [view, setView] = useState<DataFollowUpViews>("start");
	const [tripData, setTripData] = useState<any>([]);

	const handleHastus574 = (file: any) => {
		const reader = new FileReader();
		reader.onload = (event: any) => {
			// Parse data
			const bstr = event.target.result;

			console.log(bstr);

			/* for (let i = 0; i < rows.length; i++) {
				const row = rows[i];

				const key = `${row["Datum"]}-${row["Linje"]}-${row["Tur"]}`;

				tripMap[key] = row["Fullständighet"];
			}

			setTripData(tripMap); */
		};

		reader.readAsBinaryString(file);
	};

	const handleCancelledTrips = (file: any) => {
		const reader = new FileReader();
		reader.onload = (event: any) => {
			// Parse data
			const bstr = event.target.result;

			const rows: CancelledData[] = getWSJSON(bstr);

			let string = "";

			for (let i = 0; i < rows.length; i++) {
				const row = rows[i];

				const key = `${row["Trafikdatum"]}-${row["Linje"]}-${row["Tur"]}`;

				let FordonsID = "";

				string += `${FordonsID}\n`;
			}
		};

		reader.readAsBinaryString(file);
	};

	return (
		<>
			<h1 className="mt-12 text-2xl font-bold">Eftersändning</h1>
			<p className="mb-3 text-sm">
				Följ upp vilka turer som har genomförts och skapa turdata automatiskt
			</p>

			<View show={view === "start"}>
				<p className="mt-4 text-sm font-semibold">Trip rapport</p>

				<InputFile name="trips" accept=".xlsx" onHandleFile={handleHastus574} />

				<p className="mt-6 text-sm font-semibold">574</p>

				<InputFile name="trips" accept=".xlsx" onHandleFile={handleCancelledTrips} />

				<button
					className="mx-auto mt-5 self-start rounded bg-slate-200 px-6 py-3 text-xs font-semibold dark:bg-stone-900 dark:text-stone-100 dark:disabled:bg-stone-900 dark:disabled:text-stone-700"
					onClick={() => setView("query-all-records")}
				>
					Nästa
				</button>
			</View>
		</>
	);
}
